const loginsData = [
    {
        email: 'isuruariyarathna2k00@gmail.com',
        password: '200022601753',
        role: 'super-admin'
    },
    {
        email: 'emperorisuruariyarathna@gmail.com',
        password: '200022601754',
        role: 'admin',
        centerId: '0001'
    }
]

export default loginsData;