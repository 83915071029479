import React, { useEffect, useState } from 'react'

import loginsData from '../../Files/logins'
import './styles.scss'
import { Button, Tabs } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import Training from './Training'
import Attendance from './Attendance'

const Admin = () => {
    const [user, setUser] = useState(null)
    const login = JSON.parse(localStorage.getItem('login-codeclubs'))

    if (!login) {
        window.location.href = '/signin'
    }

    useEffect(() => {
        const user = loginsData.find(user => user.email === login.email)
        setUser(user)
    }, [login.email, user])

    const items = [
        {
            key: '1',
            label: 'Volunteer Training',
            children: <Training />,
        },
        {
            key: '2',
            label: 'Students Attendance',
            children: <Attendance user={user} />,
        },
    ];

    return (
        <div className='admin'>
            <div className='header'>
                <h1>
                    Code Clubs Admin Portal
                </h1>
                <div className='admin-info'>
                    <p>
                        {user?.email}
                        <br />
                        <span>
                            {user?.role}
                        </span>
                    </p>
                    <Button type='primary' onClick={() => {
                        localStorage.removeItem('login-codeclubs')
                        window.location.href = '/signin'
                    }}
                        size='large'
                        icon={<LogoutOutlined />}
                    >
                        Logout
                    </Button>
                </div>
            </div>

            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )
}

export default Admin