import React, { useEffect } from 'react'

import { PhoneFilled, MailFilled, EnvironmentFilled } from '@ant-design/icons';

import { Divider } from 'antd';

import './style.scss'


const ContactUs = () => {

    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navhelp");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);

    return (
        <div className='contact-us-page'>
            <h3 className='title-top'>
                Contact Us
            </h3>
            <h2 className='title'>
                We are here for you
            </h2>

            <div className='contact-info'>
                <a href='mailto:leaders@stemup.lk'>
                    <MailFilled />
                    <p>leaders@stemup.lk</p>
                </a>
                <Divider />
                <a href='tel:+94721811811'>
                    <PhoneFilled />
                    <p>+94 72 1811 811</p>
                </a>
                <Divider />
                <a href='/'>
                    <EnvironmentFilled />
                    <p>No:67/2, Wewalduwa,Kelaniya, 11600.</p>
                </a>
            </div>
        </div>
    )
}

export default ContactUs